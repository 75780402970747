@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,200');

body {
  margin: 0;
  font-family: 'Oswald';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* GLOBAL STYLING */

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Oswald', sans-serif !important;
  scroll-behavior: smooth;
  color: #686868;
}

p {
  font-size: 20px;
    line-height: 1.7em;
    margin-bottom: 5px;
}

body .modal {
  overflow: scroll;
}

body .modal .modal-body img {
  max-width: 100%;
}

body .modal .modal-header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.list-container li {
    padding-left: 50px;
    position: relative;
    padding-bottom: 10px;
}

.list-container li svg {
    position: absolute;
    left: 0px;
    top: 6px;
}

/* NAVBAR STYLING */

nav.navbar {
  height: 120px;
  background-color: transparent;
  border-bottom: 0;
  user-select: none;
  border-radius: 0;
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
  position: sticky;
  z-index: 10;
  font-family: 'Oswald', sans-serif;
  color: white;
  top:0;
}

nav.navbar.darkened {
    background-color: #4d4d4d;
    height: 100px;
    box-shadow: 1px 1px 10px rgba(100,100,100,0.6);
}

nav.navbar #companyName {
    margin-left: 10px;
    font-family: sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
}

body.is-homepage nav.navbar #companyName {
    opacity: 0;
}

nav.navbar.darkened #companyName,
body.is-homepage nav.navbar.darkened #companyName  {
    opacity: 1;
}

nav.navbar a, nav.navbar a:focus, nav.navbar a:hover,nav.navbar a:active, nav.navbar a svg {
  text-decoration: none;
  color: white;
  font-size:22px;
  text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
}

#ourServices a, #ourServices a:focus, #ourServices a:hover, #ourServices a:active {
    color: #686868;
    text-decoration: none;
}

nav.navbar #servicesMenu {
    text-decoration: none;
    color: white;
    font-size: 22px;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    position: relative;
}

nav.navbar #servicesDropdown {
    position: absolute;
    background-color: #4d4d4d;
    top: 62px;
    padding: 10px 5px;
    width: 240px;
    text-align: left;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba(100,100,100,0.6);
    transition: all 0.3s ease-in-out;
    opacity: 0;
}

nav.navbar a {
    transition: color 0.3s ease-in-out;
    position: relative;
}

nav.navbar a:after {
    transition: all 0.3s ease-in-out;
    position: absolute;
    content: "";
    bottom: -2px;
    width: 100%;
    height: 3px;
    left: 0;
    background-color: #ffd338;
    border-radius: 5px;
    opacity: 0;
}

nav.navbar a.selected:after {
    opacity: 1;
}

nav.navbar a svg {
    transition: all 0.3s ease-in-out;
}

nav.navbar a:hover,
nav.navbar #servicesMenu:hover {
    color: #ffd338;
}

nav.navbar a:hover svg {
    color: #ffd338;
}

nav.navbar #servicesDropdown li {
    color: white !important;
    transition: color 0.3s ease-in-out;
    margin: 5px 0;
}

nav.navbar #servicesDropdown li:hover {
    color: #ffd338 !important;
}

nav.navbar img {
  z-index: 2;
  width: 100px;
}

nav.navbar.navbar-transparent {
  background-color: transparent;
  padding-top: 15px;
  box-shadow: none;
}

nav .navbar-brand {
  padding: 5px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  margin-right: auto;
}

nav #logoImage {
  width: 135px;
  height: auto;
  margin-top: -8px;
}

nav #logoImageText {
  width: 146px;
  height: auto;
  margin-top: -6px;
}

nav .navbar-nav {
  margin-top: -30px;
}

nav .navbar-nav li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

nav .navbar-nav li.login {
  background-color: #ffd338;
  text-shadow: none;
  margin-left: 10px;
}

nav .navbar-nav li.login a:hover {
    color: white;
}

nav .navbar-nav li.telephone {
  position: absolute;
  right: 20px;
  bottom: 17px;
  font-weight: 100;
}

nav .navbar-nav li.telephone svg {
    margin-top: -5px;
    margin-right: 5px;
}

nav .navbar-header {
  position: relative;
}

nav .navbar-header:before {
  content: "";
  width: 107px;
  height: 120px;
  background-color: white;
  position: absolute;
  top: -20px;
  left: 17px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 1px 1px 10px rgba(100,100,100,0.6);
}

nav .navbar-header .navbar-toggle {
  border: solid 2px #1e378a;
  margin-top: 18px;
  position: absolute;
  display: none;
}

nav .navbar-header .navbar-toggle:hover,
nav .navbar-header .navbar-toggle:focus,
nav .navbar-header .navbar-toggle:active {
  background-color: white;
} 

nav .navbar-header .navbar-toggle .icon-bar {
  background-color: #1e378a;
  width: 31px;
    height: 3px;
}

nav .navbar-header .navbar-toggle .icon-bar+.icon-bar {
  margin-top: 6px;
}

nav .navbar-nav li.login-container a .nc-icon {
  margin-right: 10px;
  font-size: 20px;
}

nav .navbar-nav li.make-a-payment-container a .nc-icon {
  margin-right: 10px;
  font-size: 20px;
}

nav .navbar-nav li.contact-us-container a .nc-icon {
  font-size: 20px;
  margin-right: 10px;
}

nav.navbar .btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 2px white;
}

nav.navbar .btn i {
  font-size: 28px;
    position: relative;
    top: 0px;
    margin-right: 5px;
    font-weight: 100;
}

nav.navbar #mobileNavMenu.navbar-toggler .navbar-toggler-bar {
  background-color: white !important;
  width: 32px !important;
  height: 4px;
  margin-bottom: 6px;
}

nav.navbar .navbar-toggler .navbar-toggler-bar {
  background: #FFFFFF !important;
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  margin: 0 auto;
}

#servicesDropdownButton {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 22px;
    box-shadow: none;
    transition: color 0.3s ease-in-out;
    padding: 0 10px;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
    position: relative;
}

#servicesDropdownButton.selected:before {
    transition: all 0.3s ease-in-out;
    position: absolute;
    content: "";
    bottom: -2px;
    width: 100%;
    height: 3px;
    left: 0;
    background-color: #ffd338;
    border-radius: 5px;
    opacity: 0;
    z-index:1;
}

#servicesDropdownButton.selected:before {
    opacity: 1;
} 

#servicesDropdownButton:hover {
    color: #ffd338;
}

#servicesDropdownButton:after {
    content: none;
}

#servicesDropdownMenu {
    background-color: #4d4d4d;
}

#servicesDropdownMenu .dropdown-item {
    background-color: transparent !important;
}

/* HOME PAGE */

#homePageBanner {
  background-position: center;
  background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
    margin-bottom: 0;
    position: relative;
    padding: 0;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
    margin-top: -120px;
}

#homePageBanner video {
  pointer-events: none;
  object-fit: fill;
}

#homePageBanner .banner-wash {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(rgb(241 241 241 / 52%) 10%, rgb(241 241 241 / 38%) 20%, rgb(0 0 0 / 58%) 100%);
}

#homePageBanner h1, 
#homePageBanner h3 {
  z-index: 2;
}

#homePageBanner .banner-text-container {
  position: absolute;
  padding: 0 10px;
  bottom: 150px;
  user-select: none;
  background-color: rgba(255,255,255,0.3);
  padding: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  margin: 0 20px;
}

#homePageBanner .banner-text-container h1 {
  color: #ffd338;
}

#homePageBanner .banner-text-container h3 {
  padding: 0 10px;
}

#imageBanners {
    margin-top: 60px;
    margin-bottom: 60px;
}

#imageBanners .image-and-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 20px;
}

#imageBanners .image-and-text.reverse {
    flex-direction: row-reverse;
}

#imageBanners .image-and-text .text {
    text-align: right;
}

#imageBanners .image-and-text.reverse .text {
    text-align: left;
}

#imageBanners .image-and-text .image-container {
    width: 300px;
    min-width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 50%;
}

#imageBanners .image-and-text .image-container img {
    height: 100%;
    width: auto;
}

#imageBanners .image-and-text .text {
    margin-left: 40px;
    font-weight: 100;
    font-size: 26px;
/*    width: 60%;*/
}

#imageBanners .image-and-text.reverse .text {
    margin-left: 0;
    margin-right: 40px;
}

.homepage-content-container {
    position: relative;
    overflow: hidden;
    margin-bottom: -60px;
}

.homepage-content-container .smoke-background {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.1;
    z-index: -1;
    min-height: 100%;
}

.homepage-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;    
}

#ourServices {
    display: flex;
    flex-direction: row;
}

#ourServicesHeader {
    text-align: center;
    margin-top: 70px;
}

#ourServices h1 {
    text-align: center;
}

#ourServices .service {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 40px;
}

#ourServices .service:hover {
    background-color: #ffd33866;
}

#ourServices .service .icon-container {
    border: solid 3px black;
    padding: 20px;
    border-radius: 50%;
}

#ourServices .service h3 {
    font-weight: 100;
}

#ourServices .service img {
    margin-right: 50px;
}

#ourServices .service .text {
    margin-left: 20px;
}

#reviews {
    display: flex;
    flex-direction: row;
    padding: 40px 0;
    align-items: center;
    justify-content: center;
}

#reviews .review {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 50px;
    align-items: center;
    justify-content: center;
}

#reviews .review .stars-container {
    margin-bottom: 20px;
}

#reviews .review svg {
    color: #ffd338;
    font-size: 40px;
}

/* MISC */

.horizontal-rule {
    width: 80vw;
    margin: 0 auto;
    height: 2px;
    background: linear-gradient(to right, transparent 0%, #cfcfcf 50%, transparent 100%);
}

/* CUSTOMER RELATIONSHIP */

.customer-relationship {
   margin-top: -120px;
   margin-bottom: 60px;
}

.crm-content-container {
    position: relative;
    overflow: hidden;
    margin-bottom: -60px;
}


.crm-content-container .smoke-background {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.2;
    z-index: -1;
    min-height: 100%;
    min-height: 100%;
}

.customer-relationship .crm-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
}

.customer-relationship h1 {
    color: white;
    width: 100%;
    text-align: right;
    position: absolute;
    margin: 0 auto;
    top: 45%;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
    user-select: none;
    padding-right: 50px;
}

.customer-relationship p {
    margin-top: 80px;
    text-align: center;
    font-weight: 100;
    font-size: 24px;
    margin-bottom: 80px;
}

.customer-relationship ul {
    padding: 0;
    list-style-type: none;
}

.customer-relationship ul svg {
    font-size: 40px;
    margin-top: -4px;
    color: #1dbe1d;
    margin-right: 10px;
}

.customer-relationship section {
    width: 100%;
    position: relative;
}

.customer-relationship img {
    width: 100%;
}

.customer-relationship #imageBanners .image-and-text .image-container.crm {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* DEBT COLLECTION */

.debt-collection {
   margin-top: -120px;
   margin-bottom: 60px;
}

.debt-collection .dc-content-container {
    position: relative;
    margin-top: -70px;
    overflow: hidden;
    margin-bottom: -60px;
    padding-top: 70px;
}

.debt-collection .dc-content-container .smoke-background {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.1;
    z-index: -1;
    transform: rotateX(180deg);
    min-height: 100%;
}

.debt-collection .dc-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
}

.debt-collection section.text-center {
    position: relative;
    max-height: 50vh;
    overflow: hidden;
}

.debt-collection section.text-center:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #4d4d4d6b;
}

.debt-collection h1 {
    color: white;
    width: 100%;
    text-align: right;
    position: absolute;
    margin: 0 auto;
    top: 45%;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
    user-select: none;
    padding-right: 50px;
}

.debt-collection p {
    margin-top: 80px;
    text-align: center;
    font-weight: 100;
    font-size: 24px;
    margin-bottom: 80px;
}

.debt-collection ul {
    padding: 0;
    list-style-type: none;
}

.debt-collection ul svg {
    font-size: 40px;
    margin-top: -4px;
    color: #1dbe1d;
    margin-right: 10px;
}

.debt-collection section {
    width: 100%;
    position: relative;
}

.debt-collection img {
    width: 100%;
}

.debt-collection #imageBanners .image-and-text .image-container.crm {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.debt-collection .list-container {
    font-size: 26px;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
}

.debt-collection h3 {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
}

/* LEDGER MANAGEMENT */

.ledger-management {
   margin-top: -120px;
   margin-bottom: 60px;
}

.ledger-management .lm-content-container {
    position: relative;
    margin-top: -70px;
    overflow: hidden;
    margin-bottom: -60px;
    padding-top: 70px;
}

.ledger-management .lm-content-container .smoke-background {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.07;
    z-index: -1;
    min-height: 100%;
}

.ledger-management .lm-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
}

.ledger-management section.text-center {
    position: relative;
    max-height: 50vh;
    overflow: hidden;
}

.ledger-management section.text-center:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #4d4d4d6b;
}

.ledger-management h1 {
    color: white;
    width: 100%;
    text-align: right;
    position: absolute;
    margin: 0 auto;
    top: 45%;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
    user-select: none;
    padding-right: 50px;
}

.ledger-management p {
    margin-top: 80px;
    text-align: center;
    font-weight: 100;
    font-size: 24px;
    margin-bottom: 80px;
}

.ledger-management ul {
    padding: 0;
    list-style-type: none;
}

.ledger-management ul svg {
    font-size: 40px;
    margin-top: -4px;
    color: #1dbe1d;
    margin-right: 10px;
}

.ledger-management section {
    width: 100%;
    position: relative;
}

.ledger-management img {
    width: 100%;
}

.ledger-management #imageBanners .image-and-text .image-container.crm {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ledger-management .list-container {
    font-size: 26px;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
}

.ledger-management h3 {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
}

/* OUR VALUES */

.our-values {
   margin-top: -120px;
   margin-bottom: 60px;
}

.ov-content-container {
    position: relative;
    overflow: hidden;
    margin-bottom: -60px;
}

.ov-content-container .smoke-background {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.06;
    z-index: -1;
    min-height: 100%;
}

.our-values .ov-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.our-values section.text-center img {
    width: 100%;
    max-height: 50vh;
}

.our-values .ov-content p,
.our-values .ov-content h5 {
    text-align: center;
}

.our-values img {
    min-width: 100%;
}

.our-values h1 {
    color: white;
    width: 100%;
    text-align: right;
    position: absolute;
    margin: 0 auto;
    top: 45%;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
    user-select: none;
    padding-right: 50px;
}

.our-values #coreValues {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 70px auto 50px;
}

.our-values #coreValues .value {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 40px;
    justify-content: flex-start;
}

.our-values #coreValues .value .letter-icon {
    font-size: 60px;
    margin-right: 40px;
/*    border: solid 5px #ffd338;*/
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.our-values #coreValues .value .letter-icon svg {
    position: absolute;
    font-size: 138px;
    color: #ffd338;
    margin-top: 10px;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
}

.our-values #coreValues .value .letter-icon svg path {
    stroke: rgb(33, 37, 41);
    stroke-width: 0.7;
}

.our-values #coreValues .value .letter-icon .letter {
    z-index: 2;
}

.our-values #coreValues .value .content {
    width: calc(100% - 100px);
}

.our-values #coreValues .value .strapline {
    font-size: 20px;
}

.our-values #coreValues .horizontal-rule {
    display: none;
}

/* DOWNLOADS */

.downloads {
   margin-top: -120px;
   margin-bottom: 60px;
}

.downloads section.text-center {
    max-height: 50vh;
    overflow: hidden;
}

.downloads .dl-content {
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 70px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.downloads .dl-content-container {
    position: relative;
    overflow: hidden;
    margin-bottom: -60px;
    padding-bottom: 60px;
}

.downloads .dl-content-container .smoke-background {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.2;
    z-index: -1;
    min-height: 100%;
}

.downloads .dl-content h3 {
    margin-bottom: 70px;
}

.downloads img {
    width: 100%;
}

.downloads h1 {
    color: white;
    width: 100%;
    text-align: right;
    position: absolute;
    margin: 0 auto;
    top: 45%;
    text-shadow: 1.5px 1.5px 3px rgba(100,100,100,1);
    user-select: none;
    padding-right: 50px;
}

.downloads .downloadButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border: solid 2px black;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    text-decoration: none !important;
    color: rgb(33, 37, 41);
    margin-bottom: 30px;
    padding-right: 20px;
    cursor: pointer;
}

.downloads .downloadButton:hover {
    background-color: #ffd338;
/*    color: white;*/
}

.downloads .downloadButton svg {
    padding: 10px;
    color: white;
    background-color: black;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 2px 10px 2px 2px;
}

.downloads .downloadButton .filename {
    font-weight: 500;
}

/* CONTACT US */

.contact-us {
   margin-top: -120px;
   margin-bottom: 60px;
}

.contact-us h2, .contact-us h3, .contact-us p {
    text-align: center;
}

.contact-us section.text-center {
    max-height: 50vh;
    overflow: hidden;
}

.contact-us img {
    width: 100%;
    object-position: right;
}

.contact-us .cu-content {
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 70px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-us .cu-content-container {
    position: relative;
    overflow: hidden;
    margin-bottom: -60px;
    padding-bottom: 60px;
}

.contact-us .cu-content-container .smoke-background {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.2;
    z-index: -1;
    min-height: 100%;
}

.contact-us .list-container {
    font-size: 26px;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
}

.contact-us ul {
    padding: 0;
    list-style-type: none;
}

.contact-us ul svg {
    font-size: 40px;
    margin-top: -4px;
    color: #1dbe1d;
    margin-right: 10px;
}

.contact-us #contactUsFormContainer {
    padding: 30px;
    margin-bottom: 40px;
    border-radius: 10px;
    background-color: #4d4d4d;
    position: relative;
    max-width: calc(100vw - 50px);
}

.contact-us #contactUsFormContainer #contactUsForm {
    width: 500px;
    max-width: 100%;
    font-size: 20px;
}

.contact-us #contactUsFormContainer #contactUsForm textarea {
    resize: none;
    height: 150px;
}

.contact-us #contactUsFormContainer #contactUsForm button {
    width: 100%;
    background-color: #ffd338;
    color: #686868;
    border: none !important;
}

.contact-us #contactUsFormContainer #contactUsForm label {
    color: white;
}

#dismissContactSubmitError {
    margin-top: 40px;
}

#formSubmitPleaseWait,
#formSubmitFeedback {
    display: none;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #4d4d4d;
    text-align: center;
    border-radius: 10px;
    color: white;
    padding: 30px;
}

#formSubmitPleaseWait img {
    width: 100px;
    margin-bottom: 30px;
}

#formSubmitFeedback {
    display: none;
}

#formSubmitFeedback.error .success,
#formSubmitFeedback.success .error {
    display: none;
}

#formSubmitFeedback .errorIcon {
    font-size: 60px;
    background-color: red;
    border-radius: 50%;
    border: solid 3px white;
    margin-bottom: 20px;       
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#formSubmitFeedback .successIcon {
    font-size: 60px;
    background-color: #4fc64f;
    border-radius: 50%;
    border: solid 3px white;
    margin-bottom: 20px;     
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#formSubmitFeedback .errorIcon svg {
    font-size: 36px;
}

#formSubmitFeedback .successIcon svg {
    font-size: 36px;
}

#formSubmitFeedback .nc-icon {
    background-color: #1fd91f;
    font-size: 50px;
    border-radius: 50%;
    border: solid 3px white;
    color: white;
    padding: 5px;
    margin-bottom: 40px;
}


/* FOOTER */

footer {
    margin-top: 60px;
    background-color: #4d4d4d;
    padding: 40px 20px;
    color: white;
    font-weight: 100;
}

footer .container {
    max-width: 1400px !important;
}

footer .footer-text-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

footer .footer-text-container p {
    margin: 10px 0;
}

footer .footer-accreditation-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

footer .footer-accreditation-container div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

footer .footer-accreditation-container img {
    cursor: pointer;
    max-width: 100px;
    margin: 10px;
    border-radius: 5px;
}

footer .footer-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

footer .footer-image-container a {
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .footer-image-container .cyber-essentials-certified-image {
    max-width: 150px;
    padding: 10px;
}

footer .footer-text-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

/* 404 */

.error404 section.text-center img {
    width: 100%;
    margin-top: -120px;
    margin-bottom: -60px;
}

/* GLOBAL */

section.text-center img {
    object-fit: cover;
    min-height: 50vh;
    max-height: 90vh;
}

/* COOKIE CONSENT STYLING */

body .termsfeed-com---nb-standalone {
    bottom: 0;
    top: auto;
    height: auto;
    background-color: #111111d6 !important;
    font-family: "Oswald";
}

#cc-nb-title {
    font-weight: 400;
    font-size: 30px;
}

#cc-nb-text {
    font-weight: 100;
    font-size: 20px;
}

.cc-nb-buttons-container button {
    font-weight: 400 !important;
    font-size: 17px !important;
}

.cc-nb-buttons-container .cc-nb-okagree,
.cc-nb-buttons-container .cc-nb-reject {
    background-color: #ffd338;
}

#termsfeed-com---preferences-center {
    font-family: "Oswald";
}

#termsfeed-com---preferences-center .cc-pc-head-title-headline,
#termsfeed-com---preferences-center .cc-cp-body-content-entry-title {
    font-weight: 400;
}

#termsfeed-com---preferences-center .cc-cp-body-content-entry-text {
    font-weight: 100;
    font-size: 20px;
}

#termsfeed-com---preferences-center .cc-cp-body-tabs .cc-cp-body-tabs-item button {
    font-family: "Oswald";
    font-weight: 400 !important;
    font-size: 15px;
}

#termsfeed-com---preferences-center .termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox:checked+label:before,
#termsfeed-com---preferences-center .termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox+label:after {
    top: 3px !important;
}

#termsfeed-com---preferences-center .termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox+label:after {
    border: solid 2px #6d6d6d;
    top: 4px !important;
    width: 22px;
    height: 22px;
}

button.cc-cp-foot-save {
    background-color: #ffd338 !important;
    font-weight: 400 !important;
    font-size: 17px !important;
}

/* RESPONSIVE ELEMENTS */

@media screen and (max-width: 900px) {
    #imageBanners .image-and-text {
        flex-direction: column !important;
    }

    #imageBanners .image-and-text .text {
        margin-top: 40px;
        margin-bottom: 60px;
        text-align: center !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    #ourServices {
        flex-direction: column;
    }

    #ourServices .service {
        width: 380px;
    }
}

@media screen and (max-width: 700px) {
    .content .home .text-center {
        max-height: 100vh !important;
    }
    .content .text-center {
        max-height: 75vh !important;
    }
    .content .text-center img {
        height: 75vh !important;
        max-height: 75vh !important;
    }
    .our-values #coreValues .value {
        flex-direction: column;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 50px;
        margin-top: 50px;
    }
    .our-values #coreValues .horizontal-rule {
        display: block;
    }
    .our-values #coreValues .value:first-child {
        margin-top: 0;
    }
    .our-values #coreValues .value .letter-icon {
        margin: 0 0 20px 0;
    }
}

@media screen and (max-width: 576px) {
    #homePageBanner .banner-text-container {
        bottom: 5vh;
        margin-bottom: 30px;
    }
    .termsfeed-com---pc-dialog {
        margin: 0;
    }
    .termsfeed-com---pc-dialog .cc-pc-container {
        width: calc(100vw - 34px);
    }

    #imageBanners .image-and-text .image-container {
        width: 250px;
        height: 250px;
        min-width: 250px;
    }
    #ourServices .service {
        width: 100%;
    }
    .css-dqscbn-MuiSvgIcon-root {
        width: 30px !important;
        height: 30px !important;
    }
    #homePageBanner {
        align-items: flex-start;
    }
}

@media screen and (max-width: 768px) {
    footer {
        text-align: center;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px !important;
    }
}

/* SLIDE ANIMATIONS */

.slideanim {
  visibility:hidden;
}

.slide {
    animation-name: slide;
    -webkit-animation-name: slide;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    visibility: visible;
}

@keyframes slide {
    0% {
        opacity: 0;
        transform: translateY(70%);
    } 
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

/* SCROLL DOWN CHEVRONS */

.scroll-down-container {
  position: absolute;
  bottom: 120px;
  width: 24px;
  height: 24px;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@media (min-width: 992px) {
    nav.navbar #mobileNavMenu {
        display: none !important;
    }

    .navbar-collapse {
        display: inline-flex;
        flex-basis: auto;
        align-items: center;
        justify-content: flex-end;
        opacity: 1;
        flex-wrap: nowrap;
        flex-direction: row;
        transition: none;
      }

      #mobileMenuBrand {
        display: none;
      }

      main, nav, footer {
        transition: none;
      }

      .nav.navbar-nav.navbar-right  {
        min-width: 527px;
      }
      #payments .nav.navbar-nav.navbar-right {
        min-width: 468px;
      }
      .navbar-collapse:not(.show) {
        display: inline-flex;
      }

      #developerDisclaimer {
        display: none;
      }

      .mobileShow {
        display: none !important;
      }
}

/* MOBILE ADJUSTMENTS */

@media (max-width: 991px) {

    nav.navbar a.selected:after {
        opacity: 0;
    }

    nav.navbar a.selected {
        color: #ffd338;
    }

    nav.navbar #mobileNavMenu {
        outline: none !important;
        margin-left: auto;
    }

    body {
        position: relative;
    }

    .navbar-collapse.show {
        right: 230px; 
    }

    .navbar-collapse {
        display: none;
        padding: 0 !important;
        transition-delay: 0;
        background-color: #4d4d4d;
        position: fixed;
        display: block !important;
        top: 0;
        height: 100%;
        width: 230px;
        min-width: 230px;
        right: 0;
        z-index: 1032;
        visibility: visible;
        overflow-y: visible;
        border-top: none;
        text-align: left;
        transform: translateX(230px);
        transition: all 0.3s cubic-bezier(0.685, 0.0473, 0.346, 1);
      }

      #servicesDropdownButton {
        pointer-events: none;
      }

      .navbar-collapse:after {
        content: none;
      }

    .navbar-collapse .dropdown #servicesDropdownButton {
        /*text-align: center;
        width: 100%;
        pointer-events: none;
        text-decoration: underline;*/
        display: none;
    }



      .navbar-collapse ul {
        flex-wrap: initial;
        flex-direction: column;
        margin: 0;
        height: auto;
        max-height: calc(100vh - 200px);
        overflow-y: overlay;
        overflow-x: hidden;
    }

    .navbar-collapse ul li a {
        display: block;
        width:100%;
        height: 100%;
        text-align: center;
    }

      .navbar-collapse ul li {
        margin: 0;
        min-height: 50px;
        border-bottom: solid 1px rgba(255,255,255,0.5);
        padding: 0;
      }

      .navbar-collapse ul li.login {
        margin: 0;
        order: -4;
      }

      .navbar-collapse ul li.telephone {
        width: 100%;
        bottom: 50px;
        right: 0;
      }

      .navbar-collapse ul li .btn {
        width: 100%;
        border: none;
        border-radius: 0;
        margin: 0;
        min-height: 91px;
      }

      main, footer {
        transition: margin 0.3s cubic-bezier(0.685, 0.0473, 0.346, 1) !important;
        transition-delay: 0;

      }

      main.showMenu.home, nav.showMenu, footer.showMenu {
        margin: 0 230px 0 -230px;
      }

      main.showMenu {
        margin: -120px 230px 0 -230px;
      }

      nav.showMenu #companyName {
        opacity: 0 !important;
      }

      .bar1 {
        transition: top 0s;
      }
      .bar3 {
        transition: bottom 0s;
      }
      .toggled .bar1 {
        top: 10px;
      }

      .toggled .bar3 {
        bottom: 9px;
      }

      #mobileMenuBrand {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100px;
        background-color: white;
      }

      #mobileMenuCompanyName {
        color: grey;
        margin-left: 10px;
        text-transform: uppercase;
        font-family: sans-serif;
        font-size: 18px;
        text-shadow: 0px 0px 1px rgba(100,100,100,1);
      }

      #mobileMenuBrand img {
        width: 75px;
        margin-left: 20px;
      }

      #developerDisclaimer {
        color: white;
          padding: 10px;
          text-align: center;
          position: absolute;
          bottom: 0;
          width: 100%;
      }

      #developerDisclaimer a,
      #developerDisclaimer a:hover,
      #developerDisclaimer a:active,
      #developerDisclaimer a:focus {
        color: white;
        font-weight: 400;
        text-decoration: none;
      }

    .navbar .navbar-nav .dropdown-menu {
        height: 400px;
        overflow-y: scroll;
    }
    
    .navbar-toggle .icon-bar {
        display: block;
        position: relative;
        background: #fff;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        margin: 0 auto;
    }
    
    .navbar-header .navbar-toggle {
        margin-top: 12px;
        width: 40px;
        height: 40px;
    }
    
    .bar1,
    .bar2,
    .bar3 {
        outline: 1px solid transparent;
    }
    
    .bar1 {
        top: 0px;
        -webkit-animation: topbar-back 500ms linear 0s;
        -moz-animation: topbar-back 500ms linear 0s;
        animation: topbar-back 500ms 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    
    .bar2 {
        opacity: 1;
    }
  
    .bar3 {
        bottom: 0px;
        -webkit-animation: bottombar-back 500ms linear 0s;
        -moz-animation: bottombar-back 500ms linear 0s;
        animation: bottombar-back 500ms 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
  
    .toggled .bar1 {
        top: 10px;
        -webkit-animation: topbar-x 500ms linear 0s;
        -moz-animation: topbar-x 500ms linear 0s;
        animation: topbar-x 500ms 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    .toggled .bar2 {
        opacity: 0;
    }

    .toggled .bar3 {
        top: -10px;
        -webkit-animation: bottombar-x 500ms linear 0s;
        -moz-animation: bottombar-x 500ms linear 0s;
        animation: bottombar-x 500ms 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    @keyframes topbar-x {
        0% {
          top: 0px;
          transform: rotate(0deg);
        }
        45% {
          top: 6px;
          transform: rotate(145deg);
        }
        75% {
          transform: rotate(130deg);
        }
        100% {
          transform: rotate(135deg);
        }
    }

    @-webkit-keyframes topbar-x {
        0% {
          top: 0px;
          -webkit-transform: rotate(0deg);
        }
        45% {
          top: 6px;
          -webkit-transform: rotate(145deg);
        }
        75% {
          -webkit-transform: rotate(130deg);
        }
        100% {
          -webkit-transform: rotate(135deg);
        }
    }
  
    @-moz-keyframes topbar-x {
        0% {
          top: 0px;
          -moz-transform: rotate(0deg);
        }
        45% {
          top: 6px;
          -moz-transform: rotate(145deg);
        }
        75% {
          -moz-transform: rotate(130deg);
        }
        100% {
          -moz-transform: rotate(135deg);
        }
    }

    @keyframes topbar-back {
        0% {
          top: 6px;
          transform: rotate(135deg);
        }
        45% {
          transform: rotate(-10deg);
        }
        75% {
          transform: rotate(5deg);
        }
        100% {
          top: 0px;
          transform: rotate(0);
        }
    }

    @-webkit-keyframes topbar-back {
        0% {
          top: 6px;
          -webkit-transform: rotate(135deg);
        }
        45% {
          -webkit-transform: rotate(-10deg);
        }
        75% {
          -webkit-transform: rotate(5deg);
        }
        100% {
          top: 0px;
          -webkit-transform: rotate(0);
        }
    }

    @-moz-keyframes topbar-back {
        0% {
          top: 6px;
          -moz-transform: rotate(135deg);
        }
        45% {
          -moz-transform: rotate(-10deg);
        }
        75% {
          -moz-transform: rotate(5deg);
        }
        100% {
          top: 0px;
          -moz-transform: rotate(0);
        }
    }

    @keyframes bottombar-x {
        0% {
          bottom: 0px;
          transform: rotate(0deg);
        }
        45% {
          bottom: 6px;
          transform: rotate(-145deg);
        }
        75% {
          transform: rotate(-130deg);
        }
        100% {
          transform: rotate(-135deg);
        }
    }

    @-webkit-keyframes bottombar-x {
        0% {
          bottom: 0px;
          -webkit-transform: rotate(0deg);
        }
        45% {
          bottom: 6px;
          -webkit-transform: rotate(-145deg);
        }
        75% {
          -webkit-transform: rotate(-130deg);
        }
        100% {
          -webkit-transform: rotate(-135deg);
        }
    }

    @-moz-keyframes bottombar-x {
        0% {
          bottom: 0px;
          -moz-transform: rotate(0deg);
        }
        45% {
          bottom: 6px;
          -moz-transform: rotate(-145deg);
        }
        75% {
          -moz-transform: rotate(-130deg);
        }
        100% {
          -moz-transform: rotate(-135deg);
        }
    }

    @keyframes bottombar-back {
        0% {
          bottom: 6px;
          transform: rotate(-135deg);
        }
        45% {
          transform: rotate(10deg);
        }
        75% {
          transform: rotate(-5deg);
        }
        100% {
          bottom: 0px;
          transform: rotate(0);
        }
    }

    @-webkit-keyframes bottombar-back {
        0% {
          bottom: 6px;
          -webkit-transform: rotate(-135deg);
        }
        45% {
          -webkit-transform: rotate(10deg);
        }
        75% {
          -webkit-transform: rotate(-5deg);
        }
        100% {
          bottom: 0px;
          -webkit-transform: rotate(0);
        }
    }

    @-moz-keyframes bottombar-back {
        0% {
          bottom: 6px;
          -moz-transform: rotate(-135deg);
        }
        45% {
          -moz-transform: rotate(10deg);
        }
        75% {
          -moz-transform: rotate(-5deg);
        }
        100% {
          bottom: 0px;
          -moz-transform: rotate(0);
        }
    }
}

@media (max-width: 400px) {
    nav.navbar #companyName {
        font-size: 15px;
    }
    nav.navbar img {
        width: 80px;
    }
    nav .navbar-brand {
        padding-left: 0;
    }
    nav .navbar-header:before {
        width: 87px;
        top: -27px;
        left: -3px;
    }
    .homepage-content, .dc-content, .lm-content, .crm-content, .ov-content, .dl-content, .cu-content {
        padding: 15px !important;
    }

    .contact-us #contactUsFormContainer {
        padding: 15px;
    }
    #formSubmitPleaseWait,
    #formSubmitFeedback {
        padding: 10px;
    }
}

@keyframes move {

    25% {
        opacity: 1;
    }

    33% {
        opacity: 1;
        transform: translateY(30px);
    }

    67% {
        opacity: 1;
        transform: translateY(40px);
    }

    100% {
        opacity: 0;
        transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }

}

@keyframes pulse {
    to {
        opacity: 1;
    }
}

/* BACK TO TOP CONTROL */

@keyframes moveUp {
  
  25% {
    opacity: 0.4;
    transform: rotate(180deg);
  }

  33% {
    opacity: 0.6;
    transform: translateY(-10px) rotate(180deg);
  }

  67% {
    opacity: 1;
    transform: translateY(-20px) rotate(180deg);
  }

  100% {
    opacity: 0;
    transform: translateY(-30px) rotate(180deg);
  }

}

#backToTop {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}

#backToTop .chevron {
  transform: rotate(180deg);
  animation: moveUp 2s linear infinite;
}